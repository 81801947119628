/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

 import Api from '@/services/api';
 import AuthService from '@/services/auth';
 import Store from '@/store/index';

 export default {
  resetStore() {
    // reset store to empty
    Store.dispatch('users/setUsers', {
      users: [],
    });
  },

  async listAll(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['users/users'].length < 1) {
      try {
        const response = await Api().get('/user/list_all');

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            this.resetStore();
            return response.data.message;
          } else {
            Store.dispatch('users/setUsers', {
              users: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        this.resetStore();
        if (error.response) {
          return error.response.statusText;
        } else {
          await AuthService.logSupportDebug('users.ts - listAll - 45 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listUserTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['userTypes/userTypes'].length < 1) {
      try {
        const response = await Api().get('/userType/list_all');

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            this.resetStore();
            return response.data.message;
          } else {
            Store.dispatch('userTypes/setUserTypes', {
              userTypes: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        this.resetStore();
        if (error.response) {
          return error.response.statusText;
        } else {
          await AuthService.logSupportDebug('users.ts - listUserTypes - 117 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  create(params: {
    userTypeId: number,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    email: string,
  }) {
    return Api().post('/user/create', params);
  },

  update(params: {
    id: number,
    userTypeId: number,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    suspended: boolean,
  }) {
    return Api().post('/user/update', params);
  },

  delete(params: { id: number }) {
    return Api().post('/user/delete', params);
  },

};
