











































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import MaterialCard from '@/components/MaterialCard.vue';
import ConfirmActionDialog from '@/components/ConfirmActionDialog.vue';

// Models
import { User } from '@/models/User.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import UserService from '@/services/user';

// --- Third Party imports ---
import crypto from 'crypto-js';

@Component({
  components: {
    MaterialCard,
    ConfirmActionDialog,    
  },
})

export default class Users extends Vue {

  // used by Filter Area
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;
  confirmActionDelay: boolean = false;

  // used by Item Dialog for Create/Update
  showItemDialog: boolean = false;
  isUpdate: boolean = false;

  clickedItem: User = {
    id: 0,
    userTypeId: 0,
    userType: '',
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    createdAt: '',
    createdBy: 0,
    updatedAt: '',
    suspended: false,
    suspendedBy: 0,
    deletedAt: '',
    deletedBy: 0,
    isLoggedInUser: false,
  };

  // used by Confirm Action Dialog for Delete Item
  action: string = '';
  dialogContent: string = '';
  showConfirmActionDialog: boolean = false;

  title: string = '';

  rules = {
    required: (value: string) => !!value || '',
    email: (value: string) => /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value) || 'E-mail must be valid',
    cellNumber: (value: string) => /^[0-9]{10}$/.test(value) || 'Please enter a valid Phone Number',
  };

  clicked: boolean = false;

  // region Functions to display list data on page load
    headers() {
      return [
        {
          sortable: false,
          text: '',
          value: 'loggedInUserIndicator',
          align: 'end',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'First Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          text: 'Last Login At',
          value: 'lastLogin',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    async beforeMount() {
      await this.listAll();
      await UserService.listUserTypes(true);
    }

    async listAll() {
      try {
        this.isLoading = true;
        const response: string = await UserService.listAll(true);

        if (response.length > 0 && response !== 'No Data Found') {
          AppService.errorHandler(response);
        }

      } finally {
        this.isLoading = false;
      }
    }

    // #region Functions required for the Item Dialog for Create/Update/Delete
      async launchNewItemDialog() {
        this.clickedItem = {
          id: 0,
          userTypeId: 0,
          userType: '',
          username: '',
          password: '',
          firstName: '',
          lastName: '',
          email: '',
          createdAt: '',
          createdBy: 0,
          updatedAt: '',
          suspended: false,
          suspendedBy: 0,
          deletedAt: '',
          deletedBy: 0,
          isLoggedInUser: false,
        };

        this.title = 'Create New User';

        this.isUpdate = false;
        this.showItemDialog = true;
      }

      launchUpdateItemDialog(item: User) {
        this.clickedItem = Object.assign({}, item);
        

        this.title = 'Update ' + (this.clickedItem.username);

        if (this.$refs.itemForm) {
          (this.$refs.itemForm as any).resetValidation();
        }

        this.isUpdate = true;
        this.showItemDialog = true;
      }

      doCreateUpdate() {
      if (this.clickedItem.id <= 0) {
        this.doCreate();
      } else {
        this.doUpdate();
      }
    }

      async doCreate() {
        try {
          this.clicked = true;

          const response = await UserService.create({
            userTypeId: this.clickedItem.userTypeId,
            username: this.clickedItem.username,
            password: crypto.SHA256(this.clickedItem.password).toString().toUpperCase(),
            firstName: this.clickedItem.firstName,
            lastName: this.clickedItem.lastName,
            email: this.clickedItem.email,
          });

          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              AppService.errorHandler(response.data.message);
            } else {
              AppService.successHandler(response.data.message);
              await this.listAll();
              this.showItemDialog = false;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            throw new Error('response: ' + JSON.stringify(response));
          }

        } catch (error) {
          if (error.response) {
            AppService.errorHandler(error.response.statusText);
          } else {
            await AuthService.logSupportDebug('Estates.vue - doCreate - 397 - ' + error);
            AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
          }

        } finally {
          this.clicked = false;
        }
      }

      async doUpdate() {
        try {
          this.clicked = true;
          this.confirmActionDelay = true;

          const response = await UserService.update({
            id: this.clickedItem.id,
            userTypeId: this.clickedItem.userTypeId,
            username: this.clickedItem.username,
            firstName: this.clickedItem.firstName,
            lastName: this.clickedItem.lastName,
            email: this.clickedItem.email,
            suspended: this.clickedItem.suspended,
          });

          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              AppService.errorHandler(response.data.message);
            } else {
              AppService.successHandler(response.data.message);
              await this.listAll();
              this.showItemDialog = false;
              this.showConfirmActionDialog = false;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            throw new Error('response: ' + JSON.stringify(response));
          }

        } catch (error) {

          if (error.response) {
            AppService.errorHandler(error.response.statusText);
          } else {
            await AuthService.logSupportDebug('Users.vue - doUpdate - 573 - ' + error);
            AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
          }

        } finally {
          this.clicked = false;
          this.confirmActionDelay = false;
        }
      }

      async doDelete() {
      try {
        this.confirmActionDelay = true;
        const response = await UserService.delete({id: this.clickedItem.id});

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }


      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('Users.vue - doDelete - 781 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
        this.showConfirmActionDialog = false;
      }
    }
    // #endregion

    // region Functions required for the Reset Password Item Dialog for Resetting the Password

      async doResetPassword() {
      try {

        const response = await AuthService.resetPassword({id: this.clickedItem.id});
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
          AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
          } 
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
            return error.response.statusText;
          } else {
            AppService.logSupportDebug('Users.vue - doResetPassword - 798 - ' + error);
          }
      } finally {
        this.showConfirmActionDialog = false;
      }
    }

    // endregion

    // #region Functions required for the Confirm Action Dialog for Suspend/Activate/Delete/ResetPassword
    launchActivateItemDialog(item: User) {
      this.clickedItem = item;

      this.action = 'ACTIVATE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">re-activate</span> the User named <span style="font-weight: 700">' 
        + item.firstName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchSuspendItemDialog(item: User) {
      this.clickedItem = item;

      this.action = 'SUSPEND';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">suspend</span> the User named <span style="font-weight: 700">' 
        + item.firstName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchDeleteItemDialog(item: User) {
      this.clickedItem = item;

      this.action = 'DELETE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">delete</span> the User named <span style="font-weight: 700">' 
        + item.firstName + ' ' + item.lastName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchResetItemDialog(item: User) {
      this.clickedItem = item;

      this.action = 'RESETPASS';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">reset the password</span> for the User named <span style="font-weight: 700">' 
        + item.firstName + ' ' + item.lastName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;
    }

    ConfirmActionDialog_doConfirmed() {
      if (this.action === 'SUSPEND') {
        this.clickedItem.suspended = true;
        this.doUpdate();
      } else if (this.action === 'ACTIVATE') {
        this.clickedItem.suspended = false;
        this.doUpdate();
      } else if (this.action === 'DELETE') {
        this.doDelete();
      } else if (this.action === 'RESETPASS') {
        this.doResetPassword();
      } 
    }
}
